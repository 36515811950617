<i18n>
ru:
  confirm: Применить
  delete: Удалить
  enterYourPromo: Введите промокод
  giftChange: Изменить подарок
  giftSelect: Выбрать подарок
  promoErrorCode16: Сначала необходимо отменить промокод
  promoErrorCode32: 'Для получения вознаграждения Вам необходимо авторизоваться в личном кабинете, используя номер телефона. Вознаграждение будет начислено только при первом заказе с данным промокодом'
  promoErrorCode64: 'Укажите, пожалуйста, номер телефона'
  promoErrorCode128: 'Мы рады, что Вы стали нашим постоянным гостем! Но вознаграждение в рамках данной акции может быть начислено только один раз. Ознакомьтесь подробнее с правилами или воспользуйтесь другой акцией заведения'
  promoErrorCode256: 'По правилам акции Вы не можете воспользоваться реферальным кодом, который создали сами. Но можете поделиться им со своими друзьями!'
  promoErrorCode512: 'Извините, в настоящий момент акция не действует. Но Вы можете воспользоваться другой действующей акцией заведения'
  promoErrorDefault: 'Введенный Вами промокод недействителен. Вероятно, он просрочен либо уже был активирован ранее'
  remove: Удалить
  yourPromo: Ваш код
ua:
  confirm: Застосувати
  delete: Видалити
  enterYourPromo: Введіть промокод
  giftChange: Змінити подарунок
  giftSelect: Вибрати подарунок
  promoErrorCode16: Спочатку необхідно скасувати промокод
  promoErrorCode32: 'Для отримання винагороди вам потрібно авторизуватися в особистому кабінеті, використовуючи номер телефону. Винагорода буде нарахована лише за перше замовлення з цим промокодом'
  promoErrorCode64: 'Вкажіть, будь ласка, номер телефону'
  promoErrorCode128: 'Ми раді, що ви стали нашим постійним гостем! Але винагорода в рамках даної акції може бути нарахована лише один раз. Ознайомтеся докладніше з правилами або скористайтеся іншою акцією закладу'
  promoErrorCode256: 'Згідно з правилами акції ви не можете скористатися реферальним кодом, який створили самі. Але можете поділитися ним зі своїми друзями!'
  promoErrorCode512: 'Вибачте, на даний момент акція не діє. Але ви можете скористатися іншою діючою акцією закладу'
  promoErrorDefault: 'Введений вами промокод недійсний. Ймовірно, він прострочений або вже був активований раніше'
  remove: Видалити
  yourPromo: Ваш код
us:
  confirm: Confirm
  delete: Delete
  enterYourPromo: Enter your promo-code
  giftChange: Change gift
  giftSelect: Select gift
  promoErrorCode16: You need to cancel the promo code first
  promoErrorCode32: 'To receive a reward, you need to log in to your personal account using your phone number. The reward will be credited only on the first order with this promo code'
  promoErrorCode64: Please provide a phone number
  promoErrorCode128: 'We are glad that you have become our regular guest! However, the reward under this promotion can only be credited once. Please read the rules in detail or use another promotion of the restaurant'
  promoErrorCode256: 'According to the rules of the promotion, you cannot use the referral code you created yourself. But you can share it with your friends!'
  promoErrorCode512: 'Sorry, the promotion is currently not valid. But you can take advantage of another ongoing promotion of the restaurant'
  promoErrorDefault: The promo code you entered is invalid. It is likely expired or has already been activated before
  remove: Remove
  yourPromo: Your promo-code
</i18n>

<template>
  <div
    v-if="!appConfig.VueSettingsPreRun.PromocodeHidden"
    class="v-promocode-container v-mb-sm"
    :class="[
      inSmallCart
        ? 'v-col-12'
        : 'v-col-12 v-col-md-6 v-col-lg-5 v-col-xl-4 v-col-xxl-3'
    ]"
  >
    <div
      class="v-promo-inner"
      :class="{
        'v-mb-xs': promoError
      }"
    >
      <template v-if="showInput">
        <v-input
          class="v-promo-input__normalize"
          :class-name="`v-arora-input v-arora-input--${appConfig.VueSettingsPreRun.InputLayout}`"
          :class="{
            'v-promo-active': clientStore.ClientState?.data?.Promo?.IsPromoApplied,
            'v-promo-error': promoError
          }"
          :label="translate('promocode.enterYourPromo')"
          enterkeyhint="next"
          v-model:text="promoInput"
          @keyup.enter="apply"
        />

        <div class="v-w-100 v-d-flex v-flex-row v-justify-content-end">
          <arora-button
            class-name="v-btn v-btn-link-secondary v-btn-text v-no-underline v-profile-field-input__change-btn"
            ignore-settings
            :disabled="promoInput.length === 0"
            :label="translate('promocode.confirm')"
            @click="apply"
          />
        </div>
      </template>
      <div
        v-else
        class="v-promo-insert v-promo-input"
        :class="{
          'v-promo-error': promoError
        }"
      >
        <div class="v-promo-text v-ml-xs">
          <div class="v-promo-use">
            <label v-html="`${translate('promocode.yourPromo')}:`" />
            <span
              class="v-font-weight-500 v-mr-xs"
              v-html="sanitize(clientStore.ClientState?.data?.Promo?.PromoCode)"
            />
          </div>
          <ui-button
            v-if="multipleGiftsAvailable"
            :size="14"
            color="primary"
            type="text-with-button"
            width="content"
            :label="
              translate(
                multipleGiftsInCart ? 'promocode.giftChange' : 'promocode.giftSelect'
              )
            "
            :click-event="callForGiftsPopup"
          />
        </div>
        <div class="v-promo-right-part">
          <arora-button
            class-name="v-btn v-btn-square v-square-small v-btn-text"
            ignore-settings
            :label="translate('promocode.delete')"
            @click="clear"
          >
            <icon-old-general-cross />
          </arora-button>
        </div>
      </div>
    </div>
    <transition
      mode="out-in"
      name="fade"
    >
      <div
        v-if="promoError"
        class="v-error-color v-d-flex v-flex-column"
      >
        <span
          v-for="message in clientStore.ClientState?.data?.Promo?.ErrorMessages ??
          []"
          :key="message"
          class="v-promo-candidate"
        >
          <icon-old-general-information-circle class="v-promo-error-icon v-mr-xxs" />
          <span v-html="sanitize(message)" />
        </span>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { VInput } from '@arora/common'

defineProps<{
  inSmallCart?: boolean
}>()

const { callForGiftsPopup } = useCart()

const { sanitize, translate } = useI18nSanitized()
const clientStore = useClientStore()
const appConfig = useAppConfig()

onMounted(() => {
  if (import.meta.client)
    clientStore.initClientState().then(() => {
      promoInput.value = clientStore.ClientState?.data?.Promo?.PromoCode ?? ''
      showInput.value = !promoInput.value
    })
})

const promoInput = ref<string>('')
const showInput = ref<boolean>(true)

const promoError = computed<boolean>(() => {
  if (!clientStore.ClientState?.data?.Promo) return false

  return (
    clientStore.ClientState.data.Promo.ErrorMessages.length > 0 &&
    clientStore.ClientState.data.Promo.ErrorMessages[0] !== '' &&
    !clientStore.ClientState.data.Promo.IsPromoApplied
  )
})
const multipleGiftsAvailable = computed<boolean>(() => {
  return clientStore.ClientState?.data?.Cart?.MultipleGiftsAvailable ?? false
})
const multipleGiftsInCart = computed<boolean>(() => {
  return clientStore.ClientState?.data?.Cart?.MultipleGiftsInCart ?? false
})

watch(
  () => clientStore.ClientState?.data?.Cart?.MultipleGiftsAvailable ?? false,
  (newValue: boolean, oldValue: boolean) => {
    if (newValue && !oldValue && !multipleGiftsInCart.value) {
      callForGiftsPopup()
    }
  }
)

async function apply(): Promise<void> {
  await clientStore.applyPromoCode(promoInput.value)
  showInput.value =
    !clientStore.ClientState?.data?.Promo ||
    (!clientStore.ClientState.data.Promo.IsPromoApplied &&
      !clientStore.ClientState.data.Promo.PromoCode)
}

function clear(): void {
  clientStore.clearPromoCode()
  showInput.value = true
  promoInput.value = ''
}

watch(
  () => clientStore.ClientState.data?.Promo?.PromoCode,
  (newContent, oldContent) => {
    if (newContent !== oldContent) {
      promoInput.value = newContent ?? ''
      showInput.value = !promoInput.value
    }
  }
)
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-promo-error {
  border: variables.$BorderWidth solid variables.$ErrorColor;
  border-radius: variables.$BorderRadius;
}
.v-promo-use {
  font-size: variables.$TextSizeMain;
}

.v-promocode-container {
  margin-bottom: 1.1rem;

  @include mixins.sm {
    width: 100%;
  }

  .v-btn-link {
    padding: 0;
    color: variables.$PrimaryBackgroundColor;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
  }

  .v-promo-input {
    position: relative;
    padding: 5px 7px 5px 7px;
    border-radius: variables.$BorderRadius;
    background: variables.$FormBackground;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: variables.$InputShadow;
    &__normalize input {
      margin-bottom: 0;
    }
    label {
      margin-bottom: 0;
      padding: 0;
      margin-right: 5px;
    }

    .v-promo-candidate {
      position: relative;
      transition: all 0.3s ease-in-out;

      i {
        color: variables.$PrimaryBackgroundColor;
        cursor: pointer;
      }
    }

    .v-promo-code {
      margin: 0;
      padding: 0 0 0 8px;

      &.v-form-control {
        background: none;
        border: 0;
        box-shadow: none;
        width: auto;
        display: inline-block;
        height: 34px;
        font-size: 12px;
      }
    }
  }
}

.v-promo-right-part {
  display: flex;
  gap: 10px;
  align-items: center;

  .v-promo-code-error-icon {
    width: 24px;
    height: 24px;
    fill: none;
  }
}

.v-promo-error-icon {
  width: variables.$TextSizeMain;
  height: variables.$TextSizeMain;
}
</style>
